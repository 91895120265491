import React from 'react';
import connect from 'Shared/connect';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';
import { currentUrl } from '@avensia/scope';
import * as style from 'Shared/Style';
import { styled } from '@glitz/react';
import Item from './SubItem';
import { Down, Up } from 'Shared/Icon/Arrow_forward';
import cleanPath from 'Shared/cleanPath';

export function isRoot(data: MainMenuItemType[] | MainMenuItemType): data is MainMenuItemType[] {
  return Array.isArray(data);
}

type StateType = {
  openMenuItems: MainMenuItemType[];
};

type PropType = {
  menuItems: MainMenuItemType[];
  darkMode?: boolean;
};

type DarkModePropType = {
  children: React.ReactNode;
  darkMode?: boolean;
};

const SubNavBox = ({ darkMode, children }: DarkModePropType) => (
  <styled.Div
    css={{
      width: '250px',
      color: darkMode ? style.WHITE : style.primaryDark,
      backgroundColor: darkMode ? 'transparent' : style.WHITE,
      border: {
        xy: {
          width: 'thin',
          style: 'solid',
          color: darkMode ? 'rgba(255,255,255,0.5)' : style.secondaryLightest,
        },
      },
      margin: {
        top: '39px',
        right: '49px',
        bottom: '30px',
      },
      padding: {
        top: '14px',
        left: '21px',
        bottom: '30px',
      },
      height: 'auto',
    }}
  >
    {children}
  </styled.Div>
);

const ArrowContainer = styled.span({
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: {
    right: '5px',
  },
  alignItems: 'center',
  cursor: 'pointer',
});

const Arrow = {
  maxWidth: '12px',
  maxHeight: '12px',
  color: style.primary,
  marginRight: '10px',
  fontSize: '1em',
};

const ArrowDown = styled(Down, Arrow);

const ArrowUp = styled(Up, Arrow);

const ArrowUpMargin = styled(ArrowUp, {
  marginRight: '12px',
});

const DarkArrowDown = styled(ArrowDown, {
  color: style.WHITE,
});

const DarkArrowUp = styled(ArrowUpMargin, {
  color: style.WHITE,
});

const Heading = ({ darkMode, children }: DarkModePropType) => (
  <styled.P
    css={{
      font: {
        size: '16px',
        weight: 'bold',
      },
      textTransform: 'uppercase',
      color: darkMode ? style.WHITE : style.BLACK,
      margin: {
        bottom: '7px',
      },
    }}
  >
    {children}
  </styled.P>
);

const ItemRow = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const ChildRow = styled.div({
  margin: {
    left: '21px',
  },
  display: 'flex',
  alignItems: 'center',
});

const ThirdLevelRow = styled.div({
  margin: {
    left: '42px',
  },
});

const ItemNameBoxLvl1 = styled.div({
  width: '200px',
});

const ItemNameBoxLvl2 = styled.div({
  width: '176px',
});

class SubMenu extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);

    const item = currentUrl().pathname.split('/').length >= 2 ? this.getSecondLevelMenuItem() : [];
    this.state = {
      openMenuItems: item,
    };
  }

  getSecondLevelMenuItem = () => {
    let item: MainMenuItemType[] = [];
    this.props.menuItems.forEach(menu => {
      const itemFound = menu.children.find(i => i.url.split('/')[2] === currentUrl().pathname.split('/')[2]);
      if (itemFound) {
        item = [itemFound];
        if (currentUrl().pathname.split('/').length > 4) {
          const secondItem = this.getThirdLevelMenuItem(item);
          item = [itemFound, secondItem];
        }
      }
    });
    return item;
  };

  getThirdLevelMenuItem = (item: MainMenuItemType[]) => {
    let secondItemFound;
    item.forEach(menuItem => {
      secondItemFound = menuItem.children.find(i => i.url.split('/')[3] === currentUrl().pathname.split('/')[3]);
    });
    return secondItemFound;
  };

  openSublevel = (menuItem: MainMenuItemType) => {
    this.setState(prevState => ({
      openMenuItems: [...prevState.openMenuItems, menuItem],
    }));
  };

  closeSublevel = (menuItem: MainMenuItemType) => {
    this.setState({
      openMenuItems: this.state.openMenuItems.filter(item => item !== menuItem),
    });
  };

  render() {
    const pathname = currentUrl().pathname;
    const item = this.props.menuItems.find(i => i.url.split('/')[1] === pathname.split('/')[1]);
    return item && item.children.length > 0 ? (
      <div>
        <SubNavBox darkMode={this.props.darkMode}>
          <Heading darkMode={this.props.darkMode}>{item.name}</Heading>
          {item.children.map((menuItem, i) => (
            <React.Fragment key={i}>
              <ItemRow>
                <ItemNameBoxLvl1>
                  <Item
                    item={menuItem}
                    isActive={cleanPath(menuItem.url) === cleanPath(pathname)}
                    darkMode={this.props.darkMode}
                  />
                </ItemNameBoxLvl1>
                {menuItem.children.length > 0 ? (
                  this.state.openMenuItems.includes(menuItem) ? (
                    <ArrowContainer
                      onClick={() => {
                        this.closeSublevel(menuItem);
                      }}
                    >
                      {this.props.darkMode ? <DarkArrowUp /> : <ArrowUpMargin />}
                    </ArrowContainer>
                  ) : (
                    <ArrowContainer
                      onClick={() => {
                        this.openSublevel(menuItem);
                      }}
                    >
                      {this.props.darkMode ? <DarkArrowDown /> : <ArrowDown />}
                    </ArrowContainer>
                  )
                ) : null}
              </ItemRow>
              {this.state.openMenuItems &&
                this.state.openMenuItems.includes(menuItem) &&
                menuItem.children.map((childItem, i) => (
                  <React.Fragment key={i}>
                    <ChildRow>
                      <ItemNameBoxLvl2>
                        <Item
                          item={childItem}
                          isActive={cleanPath(childItem.url) === cleanPath(pathname)}
                          darkMode={this.props.darkMode}
                        />
                      </ItemNameBoxLvl2>
                      {childItem.children.length > 0 ? (
                        this.state.openMenuItems.includes(childItem) ? (
                          <ArrowContainer
                            onClick={() => {
                              this.closeSublevel(childItem);
                            }}
                          >
                            {this.props.darkMode ? <DarkArrowUp /> : <ArrowUpMargin />}
                          </ArrowContainer>
                        ) : (
                          <ArrowContainer
                            onClick={() => {
                              this.openSublevel(childItem);
                            }}
                          >
                            {this.props.darkMode ? <DarkArrowDown /> : <ArrowDown />}
                          </ArrowContainer>
                        )
                      ) : null}
                    </ChildRow>
                    {this.state.openMenuItems &&
                      this.state.openMenuItems.includes(childItem) &&
                      childItem.children.map((thirdLevel, i) => (
                        <ThirdLevelRow key={i}>
                          <Item
                            item={thirdLevel}
                            isActive={cleanPath(thirdLevel.url) === cleanPath(pathname)}
                            darkMode={this.props.darkMode}
                          />
                        </ThirdLevelRow>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </SubNavBox>
      </div>
    ) : null;
  }
}

export default connect(state => ({
  menuItems: state.mainMenu.mainMenuPrimaryItems.concat(state.mainMenu.mainMenuSecondaryItems),
}))(SubMenu);
