import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import MainMenuItemType from './MainMenuItem.type';
import * as style from 'Shared/Style';

type LinkPropType = {
  url: string;
  isActive?: boolean;
  darkMode?: boolean;
};

type ItemPropType = {
  item: MainMenuItemType;
  isActive?: boolean;
  darkMode?: boolean;
};

type PropType = LinkPropType | ItemPropType;

function isLink(props: PropType): props is LinkPropType {
  return 'url' in props;
}

const NotActiveLinkStyled = styled(Link, {
  display: 'block',
  color: style.primaryDark,
  font: {
    weight: 400,
    size: '16px',
  },
  ':hover': {
    color: style.primaryDark,
    textDecoration: 'none',
  },
  padding: {
    top: '3px',
    right: '3px',
    bottom: '3px',
  },
});

const Label = styled.span({
  display: 'block',
  color: style.primaryDark,
  ':hover': {
    color: style.primaryDark,
    textDecoration: 'none',
  },
});

const ActiveLinkStyled = styled(NotActiveLinkStyled, {
  fontWeight: 500,
});

export default class Item extends React.PureComponent<PropType> {
  render() {
    const LinkStyled = this.props.isActive ? ActiveLinkStyled : NotActiveLinkStyled;
    if (isLink(this.props)) {
      return (
        <LinkStyled to={this.props.url}>
          <Label>{this.props.children}</Label>
        </LinkStyled>
      );
    } else {
      const { url, name } = this.props.item;
      return (
        <LinkStyled to={url}>
          <Label>{name}</Label>
        </LinkStyled>
      );
    }
  }
}
