import * as React from 'react';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import { epiPropertyValue, Link, translate, isIE } from '@avensia/scope';
import { media } from '@glitz/core';
import { Down, Up } from 'Shared/Icon/Arrow_forward';

type PropType = {
  titles: any[];
  setActive: Function;
  activeTitle: string;
  anchorHeading: Scope.Property<string>;
};

type StateType = {
  isOpen: boolean;
};

class ArticleLinks extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  toggleArticleLinks = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const HEADING = epiPropertyValue(this.props.anchorHeading)
      ? epiPropertyValue(this.props.anchorHeading)
      : translate('/ContentPage/InThisArticle');
    return (
      <AnchorLinks>
        <AnchorHeader>
          <AnchorTitle>{HEADING}</AnchorTitle>
          <ArrowContainer
            onClick={() => {
              this.toggleArticleLinks();
            }}
          >
            {this.state.isOpen ? <ArrowUp /> : <ArrowDown />}
          </ArrowContainer>
        </AnchorHeader>
        {this.state.isOpen && (
          <AnchorUL>
            {this.props.titles.map((title, index) => {
              return (
                <AnchorLI key={`${title}_${index}`}>
                  {!isIE() ? (
                    <Link to={`#${title.id}`} onClick={() => this.props.setActive(title.id)}>
                      {this.props.activeTitle === title.id ? (
                        <SelectedArticleLink>{title.title}</SelectedArticleLink>
                      ) : (
                        <ArticleLink>{title.title}</ArticleLink>
                      )}
                    </Link>
                  ) : (
                    <Link to={`#${title.id}`} onDoubleClick={() => this.props.setActive(title.id)}>
                      {this.props.activeTitle === title.id ? (
                        <SelectedArticleLink>{title.title}</SelectedArticleLink>
                      ) : (
                        <ArticleLink>{title.title}</ArticleLink>
                      )}
                    </Link>
                  )}
                </AnchorLI>
              );
            })}
          </AnchorUL>
        )}
      </AnchorLinks>
    );
  }
}

const AnchorTitle = styled.h2({
  margin: {
    left: 0,
    top: '15px',
  },
  font: {
    size: '16px',
    weight: 'bold',
  },
  color: style.primaryDark,
  ...media(
    { minWidth: '768px' },
    {
      margin: {
        left: '25px',
        top: '12px',
        bottom: '7px',
      },
      fontSize: '18px',
    },
  ),
});

const AnchorHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const ArrowContainer = styled.span({
  width: '30px',
  height: '30px',
  display: 'flex',
  margin: {
    right: '5px',
    top: '7px',
  },
  alignItems: 'center',
  cursor: 'pointer',
});

const Arrow = {
  maxWidth: '12px',
  maxHeight: '12px',
  color: style.primary,
  margin: {
    right: '10px',
  },
  fontSize: '1em',
};

const ArrowDown = styled(Down, Arrow);

const ArrowUp = styled(Up, Arrow);

const AnchorLinks = styled.div({
  border: {
    bottom: {
      width: '1px',
      style: 'solid',
      color: style.primaryDark,
    },
  },
  ...media(
    { minWidth: '768px' },
    {
      margin: {
        bottom: '29px',
        x: 0,
      },
      whiteSpace: 'nowrap',
      background: '#FFF',
      width: '250px',
      overflow: 'hidden',
      border: {
        xy: {
          width: '1px',
          style: 'solid',
          color: '#D7D7D7',
        },
      },
      textDecoration: 'none',
    },
  ),
});

const AnchorUL = styled.ul({
  padding: {
    x: 0,
  },
  margin: {
    top: 0,
    bottom: '36px',
    left: '1px',
  },
  textDecoration: 'none',
  wordWrap: 'break-word',
  listStyle: 'none',
  ...media(
    { minWidth: '768px' },
    {
      marginTop: '12px',
    },
  ),
});

const AnchorLI = styled.li({
  margin: {
    bottom: '7px',
  },
  ...media(
    { minWidth: '768px' },
    {
      marginBottom: 0,
    },
  ),
});

const ArticleLink = styled.span({
  display: 'block',
  color: style.primaryDark,
  fontSize: 14,
  textDecoration: 'none',
  whiteSpace: 'pre-wrap',
  ...media(
    { minWidth: '768px' },
    {
      padding: {
        left: '10px',
      },
      fontSize: '16px',
      border: {
        left: {
          width: '15px',
          style: 'solid',
          color: 'transparent',
        },
      },
      margin: {
        top: '5px',
      },
    },
  ),
});

const SelectedArticleLink = styled(ArticleLink, {
  ...media(
    { minWidth: '768px' },
    {
      borderLeft: {
        color: style.primaryDark,
      },
      fontWeight: 500,
    },
  ),
});

export default ArticleLinks;
