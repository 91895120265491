import React from 'react';
import connect from 'Shared/connect';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';
import { currentUrl, translate } from '@avensia/scope';
import * as style from 'Shared/Style';
import { styled } from '@glitz/react';
import Item from './MobileSubMenuItem';
import cleanPath from 'Shared/cleanPath';

type PropType = {
  menuItems: MainMenuItemType[];
};

const Container = styled.div({
  width: '100%',
  color: style.primaryDark,
  backgroundColor: style.WHITE,
  border: {
    xy: {
      width: 'thin',
      style: 'solid',
      color: style.secondaryLightest,
    },
  },
  margin: {
    top: '0',
    right: '49px',
    bottom: '30px',
  },
  padding: {
    top: '14px',
    left: '21px',
    bottom: '18px',
  },
  height: 'auto',
});

const Wrapper = styled.div({
  width: '100%',
  color: style.primaryDark,
  margin: {
    top: '2em',
    bottom: '4em',
  },
});

class MobileSubMenu extends React.Component<PropType> {
  render() {
    const currentPath = cleanPath(currentUrl().pathname);
    const rootPath = currentPath.split('/')[0];
    const firstLevelItem = this.props.menuItems.find(i => cleanPath(i.url) === rootPath);
    if (firstLevelItem === undefined) {
      console.warn('MobileSubMenu:could not find first level menu item');
      return null;
    }
    const firstLevelPath = rootPath + '/' + currentPath.split('/')[1];
    const secondLevelItem = firstLevelItem.children.find(i => cleanPath(i.url) === firstLevelPath);
    // Does the current item has children? Then display those in the menu instead:
    const currentItem =
      secondLevelItem && secondLevelItem.children.length > 0
        ? secondLevelItem
        : firstLevelItem.children.length > 0
          ? firstLevelItem
          : undefined;
    return !!currentItem ? (
      <>
        <Wrapper>
          <div style={{ marginBottom: '1em' }}>{translate('/ContentPage/SubPagesMenuHeader')}</div>
          <Container>
            {currentItem.children.map((menuItem, i) => (
              <Item key={i} item={menuItem} isActive={cleanPath(menuItem.url) === currentPath} />
            ))}
          </Container>
        </Wrapper>
      </>
    ) : null;
  }
}

export default connect(state => ({
  menuItems: state.mainMenu.mainMenuPrimaryItems.concat(state.mainMenu.mainMenuSecondaryItems),
}))(MobileSubMenu);
