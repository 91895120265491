/**
 * @ComponentFor ContentPageViewModel
 */
import React from 'react';
import ContentPageViewModelType from './ContentPageViewModel.type';
import { EpiProperty, epiPropertyValue, Breakpoint, translate } from '@avensia/scope';
import { styled } from '@glitz/react';
import SubMenu from 'SiteLayout/MainMenu/submenu';
import MobileSubMenu from 'SiteLayout/MainMenu/MobileSubMenu';
import Viewport from 'Shared/Viewport';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';
import { Main, Appearance, Heading } from 'Shared/PageLayout';
import connect from 'Shared/connect';
import ArticleLinks from './ArticleLinks';

type StateType = {
  titles: Array<{ title: string; id: string }>;
  activeTitle: string;
};

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = ContentPageViewModelType &
  ConnectStateType & {
    name: string;
  };

const Content = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const CenterContent = styled.div({
  width: '100%',
  margin: {
    top: '39px',
  },
  padding: {
    x: 0,
  },
});

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '19px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      top: '80px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    maxWidth: 1800,
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const SecondaryContent = styled.div({
  margin: {
    left: '67px',
  },
  position: 'sticky',
  top: '35px',
  bottom: '35px',
  width: '250px',
  ...media(style.mediaMaxQueries[Breakpoint.Medium], {
    margin: {
      left: '30px',
    },
  }),
});

const SecondaryContentWrapper = styled.div({
  margin: {
    bottom: '29px',
    top: '39px',
  },
  position: 'relative',
});

const HeadingWrapper = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    paddingTop: '40px',
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  }),
});

const AttachmentBlockWrapper = styled.div({
  background: '#f1f2f2',
  margin: {
    x: style.medium,
    y: style.medium,
  },
  padding: {
    x: style.medium,
    y: style.medium,
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      x: 0,
      y: 0,
    },
  }),
});

const AttachmentBlockTitle = styled.h2({
  marginBottom: '7px',
  fontSize: '16px',
  lineHeight: '32px',
  color: style.primaryDark,
  fontWeight: 500,
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    fontSize: '18px',
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const MenuBkg = styled.div({
  position: 'fixed',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    position: 'absolute',
  }),
  background: style.primaryDark,
  zIndex: -1,
  top: 0,
  width: '100%',
  height: '65px',
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    height: '80px',
  }),
});

class ContentPage extends React.Component<PropType, StateType> {
  ref: React.RefObject<HTMLDivElement>;
  constructor(props: PropType) {
    super(props);
    this.ref = React.createRef<HTMLDivElement>();
    this.state = {
      titles: [],
      activeTitle: '',
    };
  }

  componentDidMount() {
    const currentTitle = window.location.hash.replace('#', '');
    this.setState({ titles: this.setIdOnElements(currentTitle) });

    document.addEventListener('scroll', () => this.scroll());
  }

  scroll = () => {
    const windscroll = window.scrollY;
    let matchingTitles: string = '';

    if (windscroll >= 285) {
      this.state.titles.map(title => {
        const articleTitle = document.getElementById(title.id);

        if (articleTitle) {
          const bodyPosition = document.body.getBoundingClientRect();
          const titlePosition = articleTitle.getBoundingClientRect();
          const offset = titlePosition.top - bodyPosition.top;
          if (offset <= windscroll + 130) {
            matchingTitles = title.id;
          }
        }
      });
      this.setActiveTitle(matchingTitles);
    }
  };

  setIdOnElements = (currentTitle: string) => {
    const container = this.ref.current;
    const headings = container.querySelectorAll('h1, h2, h3');
    const titles = [] as Array<{ title: string; id: string }>;

    Array.from(headings).map(title => {
      const trimmedTitle = title.textContent.replace(/[^A-Za-z0-9]+/gi, '-');
      title.id = trimmedTitle;
      const titleObject = { title: title.textContent, id: title.id };
      titles.push(titleObject);
    });
    if (currentTitle) {
      this.setState({ activeTitle: currentTitle });
    }
    return titles;
  };

  setActiveTitle = (title: string) => {
    this.setState({ activeTitle: title });
  };

  render() {
    const marginTopOnAttachments = this.state.titles.length > 1 ? '25px' : '0px';
    return (
      <>
        <BkgPattern />
        <MenuBkg />
        <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
          <HeadingWrapper>
            <Heading
              title={
                epiPropertyValue(this.props.content.pageTitle) ? this.props.content.pageTitle : this.props.content.name
              }
              breadcrumbs={this.props.breadcrumbs}
            />
          </HeadingWrapper>
          <Viewport>
            {(isCompact: boolean) =>
              isCompact &&
              !epiPropertyValue(this.props.content.disableAnchorLinks) &&
              this.state.titles.length > 1 && (
                <ArticleLinks
                  anchorHeading={this.props.content.anchorLinkTitle}
                  titles={this.state.titles}
                  setActive={this.setActiveTitle}
                  activeTitle={this.state.activeTitle}
                />
              )
            }
          </Viewport>
          <Content>
            {this.props.currentBreakpoint >= Breakpoint.Medium && <SubMenu darkMode={false} />}
            <CenterContent id="mainContent" innerRef={this.ref}>
              <EpiProperty for={this.props.content.mainContent} />
              {this.props.currentBreakpoint < Breakpoint.Medium && <MobileSubMenu />}
            </CenterContent>
            <Viewport>
              {(isCompact: boolean) =>
                !isCompact && (
                  <>
                    {((!epiPropertyValue(this.props.content.disableAnchorLinks) && this.state.titles.length > 1) ||
                      epiPropertyValue(this.props.content.attachmentBlocks)) && (
                      <SecondaryContentWrapper>
                        <SecondaryContent>
                          {!epiPropertyValue(this.props.content.disableAnchorLinks) &&
                            this.state.titles.length > 1 && (
                              <ArticleLinks
                                anchorHeading={this.props.content.anchorLinkTitle}
                                titles={this.state.titles}
                                setActive={this.setActiveTitle}
                                activeTitle={this.state.activeTitle}
                              />
                            )}
                          {epiPropertyValue(this.props.content.attachmentBlocks) && (
                            <AttachmentBlockWrapper style={{ marginTop: marginTopOnAttachments }}>
                              <AttachmentBlockTitle>{translate('/ContentPage/DownloadHeading')}</AttachmentBlockTitle>
                              <EpiProperty for={this.props.content.attachmentBlocks} />
                            </AttachmentBlockWrapper>
                          )}
                        </SecondaryContent>
                      </SecondaryContentWrapper>
                    )}
                  </>
                )
              }
            </Viewport>
          </Content>
          <Viewport>
            {(isCompact: boolean) =>
              isCompact &&
              epiPropertyValue(this.props.content.attachmentBlocks) && (
                <AttachmentBlockWrapper>
                  <AttachmentBlockTitle>{translate('/ContentPage/DownloadHeading')}</AttachmentBlockTitle>
                  <EpiProperty for={this.props.content.attachmentBlocks} />
                </AttachmentBlockWrapper>
              )
            }
          </Viewport>
        </MainWrapper>
      </>
    );
  }
}

export default connect<ConnectStateType>(state => ({
  currentBreakpoint: state.currentBreakpoint,
}))(ContentPage);
